// $primary: rgb(193, 175, 225);
// $secondary: rgba(193, 175, 225, 0.5);
// $navBar: rgba(195, 187, 211, 0.5);
$primary: #a69eb0;
$secondary: #c8c2cd;
$navBar: #dadae3;
$body-bg: #f8f8ff;
$primary-font: "Urbanist", sans-serif;

// Navigation

.navBar {
  padding-top: 10px;
  font-variant: all-small-caps;
  text-align: center;
}

.navLinks,
.navBrand {
  font-variant: all-small-caps;
  text-align: center;
}

.navTitle {
  font-family: "Abril Fatface", serif;
  font-size: 65px;
  cursor: crosshair;
}

.navItem {
  font-family: "Urbanist", sans-serif;
  font-size: 20px;
  padding: 0px 10px;
  margin-top: -20px;
  transition: all 0.4s;
}

.navItem:hover {
  box-shadow: 0px -3px 5px -3px $navBar;
  border-color: $navBar;
  letter-spacing: 1px;
  padding: 0px 5px;
  transition: all 0.4s;
}

.navItem a {
  cursor: crosshair;
  padding: 2px;
}