// $primary: rgb(193, 175, 225);
// $secondary: rgba(193, 175, 225, 0.5);
// $navBar: rgba(195, 187, 211, 0.5);
$primary: #a69eb0;
$secondary: #c8c2cd;
$navBar: #dadae3;
$body-bg: #f8f8ff;
$primary-font: "Urbanist", sans-serif;


.about {
  margin-top: 10%;
}

.aboutText {
  text-align: center;
  color: #000000;
  padding: 0px 30px;
}

// Skill table on about page

.skillBorder {
  border-left: 1px solid #00000080;
}

.cellBorder {
  border-bottom: 1px dashed #00000080;
}

.tableTitle {
  border-bottom: none;
  background-color: #ced5ce33;
}

.tableTitle,
.tableText {
  padding: 3px 5px;
  font-variant: all-small-caps;
  text-align: center;
  letter-spacing: 2px;
  color: #000000;
  font-size: 20px;
}

.downloadIcon {
  width: 20px;
  margin: 0px 10px 0px -5px;
}

.skill {
  font-variant: all-small-caps;
  font-size: 20px;
  letter-spacing: 1px;
}

.horizontalChart {
  display: block;
}

.verticalChart {
  display: none;
}

// RESPONSIVE

@media all and (max-width: 1000px) {

  .horizontalChart {
    display: none;
  }

  .verticalChart {
    display: block;
  }
}