// $primary: rgb(193, 175, 225);
// $secondary: rgba(193, 175, 225, 0.5);
// $navBar: rgba(195, 187, 211, 0.5);
$primary: #a69eb0;
$secondary: #c8c2cd;
$navBar: #dadae3;
$body-bg: #f8f8ff;
$primary-font: "Urbanist", sans-serif;

.projectInfo {
  text-align: center;
}

.detailTitle {
  font-family: $primary-font;
  font-size: 30px;
  text-align: center;
  font-variant: all-small-caps;
}

.featureList {
  text-align: left;
  padding-left: 15%;
  padding-top: 50px;
}

.desc {
  text-align: left;
  padding-left: 15%;
}

// responsive

@media all and (max-width: 500px) {
  .featureList {
    padding-left: 3%;
    text-align: left;
  }
}

@media all and (min-width: 501px) and (max-width: 1250px) {
  .featureList {
    padding-left: 2%;
  }
}

@media all and (min-width: 1250px) and (max-width: 1450px) {
  .featureList {
    padding-left: 10%;
  }
}