// $primary: rgb(193, 175, 225);
// $secondary: rgba(193, 175, 225, 0.5);
// $navBar: rgba(195, 187, 211, 0.5);
$primary: #a69eb0;
$secondary: #c8c2cd;
$navBar: #dadae3;
$body-bg: #f8f8ff;
$primary-font: "Urbanist", sans-serif;

@import '~bootstrap/scss/bootstrap.scss';

body {
  font-family: "Urbanist", sans-serif;
}

// Nav

.mobileNav {
  display: none;
}

// Intro / Index page
.introCard {
  font-family: 'Courier New', Courier, monospace;
  letter-spacing: 2px;
  margin-top: 7%;
  margin-bottom: 50px;
  font-variant: all-small-caps;
  font-size: 18px;
}

.homeIntro {
  margin-left: 40px;
  display: inline-block;
}

.introText {
  padding-left: 2%;
  padding-top: 5%;
  padding-bottom: 2%;
  color: #50615aae;

}

.standout {
  color: black;
  font-size: 14px;
  font-variant: normal;

}

.portrait {
  width: auto;
  height: 340px;
  margin-left: 10%;
  border-radius: 50px 10px 10px 50px;
}

// About page

.about {
  margin-bottom: 50px;
}

.desktopView {
  display: inherit;
}

.mobileView {
  display: none;
}

// Projects

.card {
  border: none;
}

.cardTitle {
  letter-spacing: 1px;
}

.cardTitle,
.featuredTitle {
  font-family: $primary-font;
  font-size: 40px;
  text-align: center;
  font-variant: all-small-caps;
}

.cardText {
  text-align: center;
}

.centeredHover {
  align-self: center;
}

.imageContainer {
  transform: translate(-200px, -50px);
  opacity: 0;
  transition: all 1s ease-in-out;
}

.project:hover .imageContainer {
  transform: translate(10px, -50px);
  padding-top: 0px;
  opacity: 1;
  transition: all 1s ease-in-out;
}

.projectImageHide {
  display: none;
}

.projectImage {
  height: auto;
  width: 0%;
  border-radius: 10px;
  padding: 2px;
  transition: all 1s ease-in-out;
}

.project:hover .projectImage {
  height: auto;
  width: 500px;
  transition: all 1s ease-in-out;
}

.projectBlurb,
.featuredBlurb {
  text-align: center;
  font-style: italic;
  padding-top: 0px;
  padding-bottom: 10px;
}

.featuredBlurb {
  padding-top: 25%;
}

.currentProject {
  font-size: 40px;
}

.featuredCard {
  border-radius: 0px;
  border: 1px solid #0000004d;
  background-color: rgba(173, 182, 173, 0.05);
}

.featuredProject {
  margin-top: 4%;
  margin-bottom: 5%;
}

.horizontalInfo {
  display: none;
}

.detailTitle {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.primaryButton,
.primaryButton:hover {
  cursor: crosshair;
}

.primaryButton,
.disabledButton {
  font-size: 15px;
  width: 100%;
  letter-spacing: 3px;
  font-variant: all-small-caps;
  border-radius: 0px;
  transition: all 0.9s;
}

.primaryButton {
  border: 1px solid #000;
  background-color: $body-bg;
  box-shadow: rgb(200, 194, 205) 2px 2px 4px 0px;
}

.primaryButton:hover {
  background-color: $body-bg;
  letter-spacing: 5px;
  box-shadow: $secondary 0px 0px 10px 2px;
  transition: all 0.9s;
}

.disabledButton {
  background-color: rgba(128, 128, 128, 0.1);
  padding-top: 7px;
  padding-bottom: 9px;
  text-align: center;
  font-style: italic;
  cursor: not-allowed;
}

.disabledButton:hover {
  letter-spacing: 5px;
  background-color: rgba(128, 128, 128, 0.3);
  transition: all 0.9s;
}

// Contact

.contact {
  margin: 7% 0% 6% 0%;
}

.contactInfo {
  margin-left: -2%;
}

.formTitle {
  margin: 7px 0px;
  letter-spacing: 1px;
  font-family: $primary-font;
  font-size: 35px;
  text-align: center;
  font-variant: all-small-caps;
}

.contactForm {
  width: 100%;
  border: 1px solid $secondary;
  border-left: 2.5px solid black;
  background-color: rgba(176, 169, 190, 0.05);
  border-radius: 0px;
}

.formLabel {
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.519);
  font-variant: all-small-caps;
}

.requiredForm {
  color: red;
}

// Footer

.footer {
  position: sticky;
  bottom: 0px;
  margin-left: -5px;
  background-color: ghostwhite;
  font-family: "Urbanist", sans-serif;
  font-variant: all-small-caps;
}

.footerIcon:hover {
  box-shadow: 0px 0px 10px 3px $secondary;
}

.iconAttribute,
.iconAttribute a {
  margin-top: -15px;
  padding-bottom: 5px;
  text-align: center;
  color: #00000085;
  text-decoration-color: #0000004d;
  text-decoration-style: dotted;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.iconAttribute a:hover {
  color: $secondary;
  text-decoration-color: $secondary;
}

// responsive

@media all and (max-width: 535px) {
  .contactInfo {
    display: none;
  }

  .contactType {
    width: 100%;
    text-align: center;
  }
}

@media all and (max-width: 991px) {
  body {
    min-width: 100%;
  }

  .mobileNav {
    display: block;
  }

  .desktopNav {
    display: none;
  }

  .mobileView {
    display: inherit;
  }

  .desktopView {
    display: none;
  }

  .navBar {
    width: 100%;
    margin-left: -2%;
  }

  .introText {
    padding-top: 50px;
    padding-bottom: 0px;
  }

  .introCard {
    width: 99%;
    margin-left: 0%;
    margin-top: 0px;
  }

  .portrait {
    margin: 0% 13%;
    border-radius: 50px 50px 10px 10px;
  }

  .about {
    margin-left: 0%;
  }

  .card {
    padding: 50px 0px;
    border: none;
    border-radius: 10px;
    min-width: 350px;
  }

  .featuredProject {
    margin-left: 0%;
    margin-bottom: 10px;
  }

  .project {
    margin-left: 0%;
  }

  .projectImage {
    min-width: inherit;
    max-width: 100%;
    min-height: auto;
  }

  .imageContainer {
    transform: translate(0%, -30%);
    opacity: 0;
    min-width: 100%;
    transition: all 1s ease-in-out;
  }

  .project:hover .imageContainer {
    transform: translate(0%, -15%);
    opacity: 1;
    min-width: 100%;
    transition: all 1s ease-in-out;
  }

  .featuredProjectImage {
    border-radius: 10px;
    margin-top: 20px;
  }

  .featuredBlurb {
    padding-top: 10%;
  }

  .projectDetails {
    width: 100%;
    margin-left: 0%;
    margin-top: 50px;
  }

  .verticalInfo {
    display: none;
  }

  .horizontalInfo {
    display: block;
    margin-left: 4%;
    width: 100%;
  }

  .detailTitle {
    border-bottom: none;
    text-align: right;
    font-size: 20px;
  }

  .projectButtons {
    width: 99%;
    margin-left: 0%;
    margin-bottom: 50px;
  }

  .contact {
    min-width: 100%;
    margin-left: 0%;
    margin-bottom: 20px;
  }

  .contactInfo {
    margin-left: 0%;
  }
}

@media all and (min-width: 992px) and (max-width: 1201px) {
  body {
    min-width: 100%;
  }

  .navBar {
    margin-left: 0%;
  }

  .navTitle {
    margin-left: 0px;
  }

  .introCard {
    margin-top: 0px;
  }

  .introText {
    min-width: 400px;
    padding: 5px 5px 0px 15px;
  }

  .aboutText {
    min-width: 80%;
  }

  .card {
    padding: 50px 0px;
    border: none;
    border-radius: 10px;
    box-shadow: none;
    // box-shadow: 0px 0px 15px 0px #50615a59;
  }

  .featuredProject {
    height: 500px;
  }

  .featuredInfo {
    margin-right: 40px;
  }

  .featuredTitle {
    margin-top: 90px;
  }

  .featuredProjectImage {
    border-radius: 10px;
    margin-top: 5px;
    margin-left: 0px;
    min-height: auto;
    width: 100%;
  }

  .projectDetails {
    width: 100%;
    margin-left: 0%;
    margin-top: 50px;
  }
}

@media all and (min-width: 1202px) {
  body {
    min-width: 100%;
  }

  .navBar {
    margin-left: 0%;
  }

  .navTitle {
    margin-left: 0px;
  }

  .introText {
    min-width: 600px;
    padding: 30px 0px 0px 10px;
  }

  .aboutText {
    width: 80%;
  }

  .featuredProject {
    height: 550px;
  }

  .featuredInfo {
    margin-top: 20px;
    margin-right: 10px;
  }

  .featuredProjectImage {
    border-radius: 10px;
    margin-top: 30px;
    min-height: auto;
    width: 100%;
  }

  .projectButtons {
    width: 75%;
    margin-left: 12%;
  }
}